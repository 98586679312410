@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gradient-to-br from-gray-900 to-gray-800 text-white min-h-screen;
  }
}

@layer components {
  .chip {
    @apply w-16 h-16 rounded-full flex items-center justify-center font-bold text-white cursor-pointer 
           transition-all duration-200 shadow-chip hover:scale-110 select-none;
  }
  
  .dice {
    @apply w-16 h-16 bg-white rounded-xl flex items-center justify-center text-2xl font-bold 
           shadow-lg transition-transform duration-300;
  }

  .btn {
    @apply px-6 py-3 rounded-lg font-semibold transition-all duration-200 
           hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
